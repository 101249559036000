<template>
  <h1 class="text-center">Mentions Légales</h1>
  <p>Edition / Publication</p>
  <p class="mb-4">
    <strong>Candy Paradis SAS</strong><br><br>
    53 rue du Rendez-Vous, 75012 PARIS Cedex - France<br>
    www.candyparadis.com<br>
    N° de TVA intracommunautaire : FR 77 831 222 542<br>
    Directrice de la publication: Sandrine Amsellem<br>
    Hébergement: OVH<br>
    Société par Actions Simplifiées au capital de 30 000 euros immatriculée au Registre du
    commerce et des sociétés de Paris Métropole sous le numéro RCS 831 22 542.<br>
  </p>
  <p>
    <strong>Conditions Générales d'Utilisation du site</strong><br><br>
    Les présentes Conditions Générales d’Utilisation (ci-après dénommées CGU) ont pour objet
    de définir les modalités et conditions dans lesquelles, Candy Paradis met à la disposition des
    utilisateurs (ci-après dénommé les Utilisateurs) du site http://candyparadis.com, (ci-après
    dénommé le Site) les services associés et la manière par laquelle les Utilisateurs accèdent au
    Site et utilisent ses services.
  </p>
  <p>
    Toute connexion au Site est subordonnée au respect des CGU.<br>
    L’Utilisateur reconnaît à ce titre que le simple accès au Site implique l’acceptation de
    l’ensemble des conditions décrites ci-après.
  </p>
  <p>
    <strong>Accès au site</strong><br>
    L'Utilisateur du Site reconnaît avoir vérifié que la configuration informatique qu’il utilise ne
    contient aucun virus et qu'elle est en parfait état de fonctionnement.
    Il est rappelé que le secret des correspondances n'est pas garanti sur le réseau Internet et
    qu'il appartient à chaque Utilisateur d'Internet de prendre toutes les mesures appropriées
    de façon à protéger ses propres données et/ou logiciels de la contamination d'éventuels
    virus circulant sur Internet.<br>
    Candy Paradis ne saurait être tenu pour responsable d'éventuels dommages, directs ou
    indirects, pouvant découler de l’accès, de l’utilisation ou de téléchargement de contenu via le
    Site, notamment, un dommage ou virus qui pourrait infecter l’ordinateur de l’Utilisateur ou
    tout autre matériel.<br>
    Candy Paradis s’efforce de permettre l’accès au Site 24 heures sur 24, 7 jours sur 7, sauf en
    cas de force majeure ou d’un événement hors de son contrôle, et sous réserve des
    éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement du
    Site et des services.<br>
    Par conséquent, Candy Paradis ne peut garantir une disponibilité du Site et/ou des services,
    une fiabilité des transmissions et des performances en termes de temps de réponse ou de
    qualité. Il n’est prévu aucune assistance technique vis-à-vis de l’Utilisateur que ce soit par
    des moyens électronique ou téléphonique.
  </p>
  <p>
    La responsabilité de Candy Paradis ne saurait être engagée en cas d’impossibilité d’accès à
    ce Site et/ou d’utilisation des services.<br>
    Par ailleurs, Candy Paradis peut être amenée à interrompre le Site ou une partie des
    services, à tout moment sans préavis, le tout sans droit à indemnités. L’Utilisateur reconnaît
    et accepte que Candy Paradis ne soit pas responsable des interruptions, et des conséquences
    qui peuvent en découler pour l’Utilisateur ou tout tiers.<br>
  </p>
  <p>
    <strong>Contenu du site</strong><br>
    Les informations et/ou documents figurant sur le Site et/ou accessibles par le Site
    proviennent de sources considérées comme étant fiables.<br>
    Toutefois, ces informations et/ou documents sont susceptibles de contenir des inexactitudes
    techniques et des erreurs typographiques.<br>
    Candy Paradis se réserve le droit de les corriger, dès que ces erreurs sont portées à sa
    connaissance.<br>
    Il est fortement recommandé de vérifier l’exactitude et la pertinence des informations et/ou
    documents mis à disposition sur le Site.<br>
    Les informations et/ou documents disponibles sur le Site sont susceptibles d’être modifiés à
    tout moment, et peuvent avoir fait l’objet de mises à jour. En particulier, ils peuvent avoir
    fait l’objet d’une mise à jour entre le moment de leur téléchargement et celui où l’Utilisateur
    en prend connaissance.<br>
    L’utilisation des informations et/ou documents disponibles sur le Site se fait sous l’entière et
    seule responsabilité de l’Utilisateur, qui assume la totalité des conséquences pouvant en
    découler, sans que la responsabilité de Candy Paradis puisse être recherchée.<br>
    Candy Paradis ne pourra en aucun cas être tenu responsable de tout dommage de quelque
    nature qu’il soit résultant de l’interprétation ou de l’utilisation des informations et/ou
    documents disponibles sur le Site.<br>
  </p>
  <p>
    <strong>Propriété intellectuelle</strong><br>
    Le Site est la propriété exclusive de Candy Paradis.<br>
    La structure générale du Site et son contenu sont la propriété exclusive de Candy Paradis, et
    font l'objet d'une protection légale au titre de la propriété intellectuelle (droits d'auteur,
    bases de données, logiciels, marques, etc.).<br>
    Toute reproduction, représentation ou adaptation totale ou partielle du Site par une
    personne physique ou morale, par quelque procédé que ce soit, sans l'autorisation expresse
    de Candy Paradis est interdite et constituerait un acte de contrefaçon sanctionné
    notamment par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
    Toute réutilisation ou extraction totale ou partielle, des bases de données du Site par une
    personne physique ou morale, par quelque procédé que ce soit, est interdite et constituerait
    un acte de contrefaçon sanctionné par les articles L. 343-1 et suivants du Code de la
    propriété intellectuelle.<br>
    Toute reproduction totale ou partielle des marques et logos de Candy Paradis réalisées sans
    son autorisation expresse est interdite en application des articles L. 713-2 et suivants du
    Code de la propriété intellectuelle.<br>
  </p>
  <p>
    <strong>Protection des données personnelles</strong><br>
    Conformément à la loi Informatique et Libertés n°78-17 du 6 janvier 1978 modifiée et au
    Règlement Général sur la Protection des Données à caractère personnel (ci-après « RGPD »)
    n° 2016/679/UE du 27 avril 2016, Candy Paradis, en qualité de responsable de traitement,
    garantit le respect de la vie privée des Utilisateurs du Site.
  </p>
</template>

<script>
export default {
  name: "cguVue",
  data() {
  },
  components: {},
}
</script>

<style lang="scss"></style>