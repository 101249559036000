<template>
  <div class="my-5">
  <h1 class="text-center">CONDITIONS GENERALES DE VENTE</h1>
  <p>Candy Paradis et ses filiales</p>

  <hr>
  <p>
  Article 1 : Préambule <br>
  - La société Candy Paradis ou ses filiales seront dénommées ci-après dans les présentes « Le
  Vendeur ».<br>
  - Toute personne physique ou morale passant, auprès du Vendeur commande sera dénommée
  ci-après dans les présentes « L’Acheteur ».<br>
  - On entend par « Produits » les marchandises fabriquées, produites et/ou vendues par le
  Vendeur.<br>
  - La ou les « Commandes » s’entendent comme tous les moyens par lesquels l’Acheteur
  manifeste sa volonté d’acquérir les Produits du Vendeur.<br>
  - Les présentes conditions générales de vente ci-après dénommées « CGV » doivent s’entendre
  comme l’ensemble des conditions et modalités de prix/tarif, réductions de prix, logistiques,
  commandes, en vigueur et applicables dans la relation d’achat / vente ; elles priment sur tout
  autre accord. Elles sont le socle de la négociation commerciale. Dès la Commande, l’Acheteur
  est réputé avoir pris connaissance des présentes CGV, y adhérer de façon pleine et entière
  sans aucune restriction de sa part et ne peut y déroger de quelque manière que ce soit. Le fait
  que le Vendeur ne se prévale pas, à un moment donné, d’une quelconque des présentes
  clauses ne peut être interprété comme valant renonciation à s’en prévaloir ultérieurement.<br>
  - Les présentes CGV s’appliquent à la France.<br>
  - Les présentes CGV annulent et remplacent toutes versions antérieures.<br>
  </p>
  <p>
  Article 2 : Commandes et privatisation<br>
  2.1. Validation de commande<br>
  Toute commande est enregistrée si un acompte d’au moins 50% est versé.<br>
  Tout acompte versé ne pourra être remboursé. Il est transformé en avoir valable 6 mois après la date
  d’annulation de la commande.<br>
  La livraison donne lieu à un devis personnalisé.<br>
  Aucune délivrance du produit commandé ne pourra être honorée sans son règlementation total au
  plus tard 72 heures avant la date de l’événement.<br>
  2.2 Privatisation pour événements<br>
  Le nombre de convives précisé lors de la réservation sera le nombre final retenu à régler lors de la
  prestation. Le tarif fixé par personne lors de la signature du contrat sera ferme et définitif.<br><br>

  Candy Paradis SAS au capital de 30 000€ - 53 rue du rendez-vous 75012 Paris – immatriculée au RCS de Paris –
  N° TVA Intracommunautaire FR 77 831 222 542<br>
  Le nombre d'invités devra être fixé 10 jours avant la manifestation. A partir de cette date il sera
  ferme et définitif et aucun désistement ne sera pris en compte.<br>
  Si le nombre d'invités présents lors de la manifestation dépasse vos prévisions, la différence sera
  exigée en fin de réception<br><br>
</p>
<p>
  Article 3 : Livraison des produits<br>

  3.1. Livraison des produits à l’adresse et en présence de l’Acheteur<br>
  Notre prestataire propose la livraison sur des créneaux de 3 heures, sur devis selon l’adresse exacte
  de l’Acheteur. Le choix du créneau de livraison pourra être modifié au plus tard 3 jours avant la date
  souhaitée. La livraison est payable en intégralité et fait partie de la prestation du Vendeur.
  Un supplément pour livraison sur rendez-vous est possible.<br>
  Il appartient à l’Acheteur de vérifier le nombre et l’état des Produits au moment de la réception. Le
  transfert des risques s’effectue au moment de la livraison des Produits. Une fois les Produits acceptés
  par l’Acheteur, le Vendeur n’acceptera pas les réclamations. Les délais de livraison sont donnés à titre
  indicatif, sans garantie et doivent prendre en compte les contraintes liées au mode de transport, ainsi
  qu’à la distance à parcourir. Sauf accord entre le Vendeur et l’Acheteur, les dépassements de délais
  indicatifs ne peuvent donner lieu au paiement de dommages et intérêts ou à une annulation de
  commande.<br>
  3.2. Livraison des produits à une autre adresse sur demande de l’Acheteur<br>
  Dans l’hypothèse où l’Acheteur souhaiterait que les produits soient livrés à une autre adresse que la
  sienne, cette demande devra impérativement mentionner :<br>
  - le nom de la personne morale ou physique à qui les Produits devront être remis,<br>
  - l’adresse de livraison.<br><br>
  La livraison se fera aux risques et périls de l’Acheteur. Les Produits seront automatiquement et
  immédiatement considérés comme acceptés par l’Acheteur. Toute réclamation portant sur la livraison
  des Produits devra être formulée par tous moyens écrits le jour même de la livraison et pendant les
  heures d’ouvertures du Vendeur. À défaut, le Vendeur n’acceptera aucune réclamation. L’Acheteur
  s’engage en outre à signer une décharge de responsabilité au profit du Vendeur. En cas d’impayé,
  l’Acheteur ne pourra revendiquer l’absence de signature du ou des bon(s) de livraison. Les délais de
  livraison sont donnés à titre indicatif, sans garantie et doivent prendre en compte les contraintes liées
  au mode de transport, ainsi qu’à la distance à parcourir. Sauf accord entre le Vendeur et l’Acheteur,
  les dépassements de délais indicatifs ne peuvent donner lieu au paiement de dommages et intérêts
  ou à une annulation de commande.<br>
  3.3. Incident ou retard de livraison<br>
  Le Vendeur ne pourra être tenu responsable du moindre retard ou incident de livraison.<br><br>

  Candy Paradis SAS au capital de 30 000€ - 53 rue du rendez-vous 75012 Paris – immatriculée au RCS de Paris –
  N° TVA Intracommunautaire FR 77 831 222 542<br>
  </p>
  <p>
  Article 4 : Prix<br><br>

  4.1. Tarifs en vigueur<br>
  Nos prix sont donnés sans engagements dans la durée et nos ventes sont toujours faites au tarif en
  vigueur au jour de la livraison.<br>
  4.2. Evolution des prix<br>
  Nous nous réservons le droit de réviser nos prix à tout moment.<br>
</p>
<p>
  Article 5 : Conditions de règlement<br><br>

  5.1. Moyen de règlement<br>
  L’Acheteur a pour obligation de régler ses factures par espèces, virement ou carte bleue.<br>
  5.2. Délais de règlement<br>
  Aucun délai fournisseur n’est accordé.<br>
  L’Acheteur a obligation de régler sa (ses) facture(s) à réception de celle(s)-ci. Aucune prestation ne
  pourra avoir lieu sans réception de la totalité de la somme due au moins 5 jours avant la livraison.
  5.3. Retard de paiement et pénalités<br>
  En cas de retard de paiement de plus de 15 jours à compter de la date mentionnée sur la facture, il
  sera dû, en sus des pénalités de retard, une indemnité forfaitaire de frais de recouvrement de 40 euros
  (Loi du 22 mars 2012 et article L 441-3 du Code de Commerce). Le taux des pénalités de retard est égal
  à 3 fois le taux d’intérêt légal en vigueur.<br>
  5.4. Acomptes et règlements anticipés<br>
  Tout acompte ou règlement effectué en amont ne saurait donner lieu à remboursement. En cas
  d’annulation ou de modification d’événement, la somme versée sera transformée en avoir valable en
  boutique 6 mois.<br>
  Cet article fait aussi foi en cas de fermeture imposée suite décisions des autorités gouvernementales.
  </p>
  <p>
  Article 6 : Evénement indépendant de la volonté du Vendeur<br><br>

  6.1. Cas de force majeure<br>
  Les obligations du Vendeur seront suspendues en cas de survenance d’un cas fortuit, de force majeure
  ou d’événement indépendant de la volonté du Vendeur qui en gênerait ou en retarderait l’exécution.
  Est considéré comme tel tout événement de quelque nature qu’il soit, échappant raisonnablement à
  son contrôle, tel que, sans que ceci soit limitatif, la pénurie/le manque de matière première, la guerre,
  les émeutes, l’insurrection, les troubles sociaux, grève totale ou partielle entravant la bonne marche
  du laboratoire ou des livreurs, problèmes sanitaires, interruption des transports, des fournitures et
  des matières premières, d’énergie, les conditions météorologiques exceptionnelles, incendie,
  inondation, pollution, etc...<br><br>

  Candy Paradis SAS au capital de 30 000€ - 53 rue du rendez-vous 75012 Paris – immatriculée au RCS de Paris –
  N° TVA Intracommunautaire FR 77 831 222 542<br>
  6.2. Produits hors saison<br>
  En cas d’impossibilité de trouver les produits ou de rupture, la direction se réserve le droit de modifier
  les recettes des entremets proposés.<br>
  </p>
  <p>
  Article 7 : Responsabilité<br><br>

  7.1. Responsabilité de l’Acheteur<br>
  Le Vendeur ne pourra en aucun cas être tenu responsable envers l’Acheteur des préjudices financiers,
  tels que, notamment, perte de bénéfices, perte de commandes, perte d’image, perte d’usage, perte
  d’intérêts, et de manière générale de tous les dommages indirects et/ou immatériels (consécutifs ou
  non) subis par l’Acheteur ou un quelconque tiers du fait d’un défaut ou d’une perte d’usage des
  Produits.<br>
  7.2. Choix des Produits<br>
  L’Acheteur est seul responsable du choix des Produits qu’il commande et leur adéquation à ses
  besoins
  </p>
  <p>
  Article 8 : Propriété intellectuelle et confidentialité<br><br>

  8.1. Propriété du Vendeur<br>
  Les informations et données techniques ou commerciales contenues dans tout document ou support
  d’information fournis par le Vendeur dans sa proposition ou en relation avec la commande (y compris
  formulations, recettes, compositions, procédés, etc...) resteront, la propriété exclusive du Vendeur
  ainsi que tous droits de propriété intellectuelle y afférents. Le Vendeur conserve et conservera
  l’entière propriété de toutes inventions, formulations, recettes, compositions, procédés développés
  par le Vendeur antérieurement ou pendant l’exécution de la commande.<br>
  8.2. Confidentialité<br>
  Toutes les informations échangées entre l’Acheteur et le Vendeur doivent être traitées de manière
  confidentielle et ne peuvent pas être divulguées à des tiers, sauf en cas d’accord préalable écrit de
  l’autre partie ou d’obligation légale.
  </p>
  <p>
  Article 9 : Clause de non-exclusivité<br>

  Le Vendeur ne garantit aucune exclusivité sur les Produits.<br><br>

  Nom ..............................<br><br>

  Date ................................ Signature ..............................<br>
  </p>
  </div>
</template>

<script>
export default {
  name: "cguVue",
  data() {
  },
  components: {},
}
</script>

<style lang="scss"></style>