import { createWebHistory, createRouter } from "vue-router";  
import CGU from "./components/CGU/cgu.vue";
import CGV from "./components/CGV/cgv.vue";
import Information from "./components/Section/Information.vue";

const links = [
  {
    path: "/",
    name: "cp-Information",
    component: Information,
  },
  {
    path: "/cgu",
    name: "Home",
    component: CGU,
  },
  {
    path: "/cgv",
    name: "CGV",
    component: CGV,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: links,
});

export default router;