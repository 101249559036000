<template>
  <div class="container">
    <div class="text-center d-flex justify-content-around p-3 mb-4">
    <router-link class="btn-link" to="/cgu">CGU</router-link>
    <router-link class="btn-link" to="/cgv">CGV</router-link>
    <router-link class="btn-link" to="/">Home</router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-center">© 2021 Candy Paradis</p>
      </div>
    </div>
  </div>
</template>
<script>
</script>
<style lang="scss" scoped>
</style>