<script>
import Header from "./components/Header/Header.vue"
import navigation from "./components/Nav/Navigation.vue"
// import Information from "./components/Section/Information.vue"
import Footer from "./components/Footer/Footer.vue"

import "@splidejs/vue-splide/css"

export default {
  name: "App",
  title: "Candy Paradis",
  data() {
    return {}
  },
  components: {
    Header,
    navigation,
    // Information,
    Footer
  },
}
</script>

<template>
  <div class="container">
    <Header />
    <navigation />
    <div class="main">
      <router-view />
      <!-- <Information /> -->
    </div>

    <footer>
      <Footer />
    </footer>
  </div>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

.red {
  background-color: red;
}

body {
  background-color: #fef7f6;
}

img {
  width: 100%;
}

header {
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .logo {
    margin: 0 2rem 0 0;
  }
}

@media (max-width: 769px) {
  .main {
    margin: 2rem 0;
    text-align: justify;
  }

  .logo {
    width: 160px;
  }
}

#app {
  width: 100%;
}

:root {
  /*--primary: #7fc6cc;*/
  --primary: #337b80;
  --body-color: #fef7f6;
  --primary-light: #e7f9f9;
  --accent: #0652dd;
  --warning: #ea2027;
  --success: #009432;
  --font-family: "Open Sans", sans-serif;
  --box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px rgb(60 64 67 / 15%);
  --border-radius: 3px;
  --border: 1px solid var(--gray-light);
  --color: #636466;
  --color-dark: #444;
  --color-primary: #fff;
  --gray: #636466;
  --gray-dark: #444;
  --gray-light: #ddd;
  --max-width: 1200px;
  --footer-background: #0a3d62;
  --font-size-small: 14px;
  --font-size-regular: 16px;
  --font-size-big: 24px;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}

* {
  box-sizing: border-box;
}

body {
  background: var(--body-color);
}

.btn {
  border: 1px solid var(--primary);
}

.btn:hover {
  background: var(--primary);
  color: #fff;
}

img {
  width: 100%;
}

.text-uppercase {
  text-transform: uppercase;
}

a,
a:active,
a:visited {
  color: #000;
  text-decoration: none;
  transition: color 0.25s ease;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}


.block-title {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.title-trait {
  font-size: var(--font-size-big);
  margin-bottom: 35px;
}

.title-trait::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 40px;
  background-color: var(--primary);
  left: 50%;
  top: -53px;
}

.main {
  margin-bottom: 30px;
}
</style>
